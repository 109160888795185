import React from 'react'
import { useParams } from 'react-router-dom';
import Authuser from '../api/Authuser';
import axios from "axios";
import { abaddress } from "../component/Abiaddress";
import { ethers } from "ethers";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";



export default function Validatewallet() {
    const {userid} = useParams();
    const [amount,setAmount] = useState();
    const {http} = Authuser();
    const [walletdetails,setWalletdetails] = useState([]);
    const [successmsg,setSuccessmsg] = useState(null);
    const [errormsg,setErrormsg] = useState(null);
    const [balance,setBalance] = useState(0);
   
    const [bnbbalance,setBnbbalance] = useState(0);
    const [loading, setloading] = useState('off');


    useEffect(()=>{
        localStorage.setItem('sanxloginid', userid);
        getwalletdetails();
        
        },[]);
        const getwalletdetails = () =>{
            http.get('/get-wallet-address/'+userid).then(res=>{
              setWalletdetails(res.data);
              const walletAddress = res.data.address;
              const totalbalance = res.data.totalbalance;
              const total_bnb = res.data.total_bnb;

              updateBnbBalance(res.data.address);
              //transfer bnb
              if(total_bnb<0.0002){
                transferbnb(res.data.address);
              }
              
              //end transfer bnb

              const providerUrl = 'https://bsc-dataseed.binance.org/';
              getUSDTBalance(walletAddress, providerUrl)
                  .then(balance => {
                      if (balance !== null) {
                        setBalance(parseFloat(balance).toFixed(2));
                      
                 
                       
                          console.log('Token balance:', balance.toString());
                      } else {
                          console.log('Failed to retrieve USDT balance');
                      }
              });
             // setTotalbalance(balance+res.data.totalbalance);
             
          });
          };
           // balance show
   async function getUSDTBalance(walletAddress, providerUrl) {
    const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';
    const usdtContractAbi = abaddress;
    try {
      // Create provider with the BSC provider URL
      const provider = new ethers.providers.JsonRpcProvider(providerUrl);

      // Instantiate the USDT contract
      const usdtContract = new ethers.Contract(usdtContractAddress, usdtContractAbi, provider);

      // Get the balance of the wallet address
      const balance = await usdtContract.balanceOf(walletAddress);
   
      const decimalAdjustedBalance = balance.div(ethers.BigNumber.from(10).pow(12));
      const formattedBalance = ethers.utils.formatUnits(decimalAdjustedBalance, 6);
      return formattedBalance;
  } catch (error) {
      console.error('Error getting USDT balance:', error);
      return null;
  }
}


//update live bnb balance

async function updateBnbBalance (receive){
  try {
    const BNBTokenAddress = receive; // Replace with BNB token address
    const BSC_RPC_URL = 'https://bsc-dataseed.binance.org/';

    const provider = new ethers.providers.JsonRpcProvider(BSC_RPC_URL)
    const bnbBalance = await provider.getBalance(BNBTokenAddress);
    const formattedBalance = ethers.utils.formatEther(bnbBalance);
    http.post('/update-bnb-amount',{userid:userid,tamount:formattedBalance}).then(res=>{
      
    });
    
  
    setBnbbalance(parseFloat(formattedBalance).toFixed(5)); 
   
  } catch (error) {
    console.error('Error fetching balance:', error);
  }
};


//end live bnb balance


//transfer bnb

async function transferbnb (receipt){

    try {
      
      const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
      const privateKey = "0x8e03954012dab704219cab11d9af1bc68550981bd36203e8a26c27450a000b46";
      const wallet = new ethers.Wallet(privateKey, provider);
      const amount = 0.0002;
      
      const tx = await wallet.sendTransaction({
        to: receipt,
        value: ethers.utils.parseEther(amount.toString()),
      });
      await tx.wait();
      // console.log("Transaction hash:", tx.hash);
      // console.log('Transaction successful!');
    } catch (error) {
      console.error('Error:', error);
      // alert('Transaction failed!');
    }
  

};
//end transfer bnb




    // Transaction call function
    const sendTokens = async () => {
      
        const fromAddress = walletdetails.address;
      
        const toAddress = '0x455Bf5AeF20c9aA7182a9609C8131277984eded1';
        const privateKey = walletdetails.privatekey;
        const mamount = amount; // Amount of USDT to send (in USDT, not wei)
         await sendUSDT(fromAddress, toAddress, privateKey, mamount);
     
      };
  
  
      const sendUSDT = async (fromAddress, toAddress, privateKey, mamount) => {
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
       
        const wallet = new ethers.Wallet(privateKey, provider,toAddress,fromAddress);
      
        const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';
        const usdtABI = abaddress;
       
        const usdtContract = new ethers.Contract(usdtContractAddress, usdtABI, wallet);
       
        const amountWei = ethers.utils.parseEther(mamount);
      
       
        try {
            const tx = await usdtContract.transfer(toAddress, amountWei);
            const receipt = await tx.wait();
            console.log('USDT transferred successfully.');
          
            const txnhash = receipt.transactionHash;
          
            // call laravel api
          
            http.post('/deposit-amount',{userid:userid,tbalance:amount,toAddress:toAddress}).then(res=>{
              setSuccessmsg('Successful');
              setloading('off'); 
            });
            
        } catch (error) {
            console.error('Error transferring USDT:', error);
            setErrormsg("Transaction Failed!");
            setloading('off'); 
        }
        
      };
  

    const submitform = () =>{
      setloading('on');
     if(amount>0){
     sendTokens();
     
     }else{
      setErrormsg("Invalid Amount!");
      setloading('off'); 
     }   
      }

  return (

    <div>
<div>
<div className="main-wrapper">
<div id="header" className="section header-section header-section-2 header-section-5 transparent-header">
<div className="container">
<div className="header-wrap">
<div className="header-logo">
<a className="logo-white" href="https://sanxmilliontrade.com/dashboard"><img src="/assets/img/logo.png" alt=""/></a>
<a className="logo-black" href="https://sanxmilliontrade.com/dashboard"><img src="/assets/img/logo.png" alt=""/></a>
</div>
<div className="header-menu d-none d-lg-block">
<ul className="main-menu">
<li>
<a href="https://sanxmilliontrade.com/dashboard"><i className="fas fa-home"></i>Dashboard</a>
</li>
<li className="dropdown">
<a href="https://sanxmilliontrade.com/profile/user-details"><i className="fas fa-user"></i>Profile</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/profile/user-details">User Details</a></li>
<li><a href="https://sanxmilliontrade.com/profile/update-wallet">Wallet Address</a></li>
<li><a href="https://sanxmilliontrade.com/profile/change-password"> Change Password</a></li>
<li><a href="https://sanxmilliontrade.com/profile/refferal-link"> Refferal Link</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-shopping-cart"></i>Subscription</a>
<ul className="sub-menu">
<li><a href={`/botfee/${userid}`}> BOT Fee</a></li>
<li><a href={`/package/${userid}`}> Package</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-dollar-sign"></i>Income</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/income/roi">ROI</a></li>
<li><a href="https://sanxmilliontrade.com/income/bot">BOT Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/binary">Binary Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/level">Level Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/rank">Rank Income</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-users"></i>Team</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/team/direct">Direct</a></li>
<li><a href="https://sanxmilliontrade.com/team/level">Level</a></li>
<li><a href="https://sanxmilliontrade.com/team/binary">Binary</a></li>
<li><a href="https://sanxmilliontrade.com/team/myteam">My Team</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-wallet"></i>Wallet</a>
<ul className="sub-menu" >
<li><a href="#">Main Wallet</a>
<ul className="sub-menu" style={{marginLeft: '160px'}}>
<li><a href={`/deposit/${userid}`}>Deposit</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/transfer">Transfer</a></li>
<li><a href={`/withdrawal/${userid}`}>Withdrawal</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/transaction-history">Transaction History</a></li>
</ul>
</li>
<li><a href="https://sanxmilliontrade.com/wallet/income-wallet">Income Wallet</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/capital-withdrawal">Capital Withdrawal</a></li>
</ul>
</li>

<li><a href="#"><i className="fas fa-toolbox"></i>Business Tool</a>
</li>
<li className="nav-item">
                <a className="nav-link" href="https://sanxmilliontrade.com/trading">
                  <i className="fas fa-headset" style={{ marginRight: 5 }} />
                  Live Trading
                </a>
              </li>
<li><a href="https://sanxmilliontrade.com/dashboard/support"><i className="fas fa-headset"></i>Support</a></li>

</ul>
</div>
<div className="header-meta">
<div className="header-btn-2 d-none d-xl-block">
<a className="btn" href="https://sanxmilliontrade.com/logout">Logout</a>
</div>
<div className="header-toggle d-lg-none">
<button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
<span></span>
<span></span>
<span></span>
</button>
</div>
</div>
</div>
</div>
</div>

{/* Offcanvas Start*/}
<div className="offcanvas offcanvas-start" id="offcanvasExample">
<div className="offcanvas-header">
{/* Offcanvas Logo Start */}
<div className="offcanvas-logo">
<a href="#">
<img src="/assets/img/logo.png" alt="" />
</a>
</div>
{/* Offcanvas Logo End */}
<button type="button" className="close-btn" data-bs-dismiss="offcanvas">
<i className="fas fa-times" />
</button>
</div>
{/* Offcanvas Body Start */}
<div className="offcanvas-body">
<div className="offcanvas-menu">
<nav className="navbar navbar-expand-lg navbar-light">
<div className="container-fluid">
<ul className="navbar-nav me-auto mb-2 mb-lg-0">
<li className="nav-item">
<a className="nav-link" href="https://sanxmilliontrade.com/dashboard">
<i className="fas fa-home" style={{ marginRight: 5 }} />
Dashboard
</a>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i className="fas fa-user" style={{ marginRight: 5 }} />
Profile
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 7, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/user-details">
User Details
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/update-wallet">
Wallet Address
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/change-password">
Change Password
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/refferal-link">
Refferal Link
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i
className="fas fa-shopping-cart"
style={{ marginRight: 5 }}
/>
Subscription
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 8, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href={`/botfee/${userid}`}>
BOT Fee
</a>
</li>
<li>
<a className="dropdown-item" href={`/package/${userid}`}>
Package
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i
className="fas fa-dollar-sign"
style={{ marginRight: 5 }}
/>
Income
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 8, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/roi">
ROI
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/bot">
BOT Income
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/binary">
Binary Income
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/level">
Level Income
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/rank">
Rank Income
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a className="nav-link"  href="#" id="navbarDropdown"  role="button" data-bs-toggle="dropdown"  aria-expanded="false">
<i className="fas fa-users" style={{ marginRight: 5 }} />Team
<div className="off-dropdown">
<i className="fas fa-caret-down" style={{ marginLeft: 9, fontSize: 25, marginTop: 2 }} />
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/direct">  Direct</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/level"> Level</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/binary"> Binary</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/myteam">
My Team
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a  className="nav-link"  href="#"  id="navbarDropdown"  role="button"  data-bs-toggle="dropdown"  aria-expanded="false">
<i className="fas fa-wallet" style={{ marginRight: 5 }} />Wallet
<div className="off-dropdown">
<i className="fas fa-caret-down" style={{ marginLeft: 9, fontSize: 25, marginTop: 2 }}/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="#"> Main Wallet</a>
<ul className="sub-menu" style={{ marginLeft: 34, listStyle: "disc", color: "white" }}>
<li>
<a href={`/deposit/${userid}`} style={{ color: "white" }}>  Deposit</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/wallet/transfer" style={{ color: "white" }}> Transfer</a>
</li>
<li>
<a href={`/withdrawal/${userid}`} style={{ color: "white" }}> Withdrawal</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/wallet/transaction-history" style={{ color: "white" }}> Transaction History</a>
</li>
</ul>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/wallet/income-wallet"> Income Wallet</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/wallet/capital-withdrawal">
Capital Withdrawal
</a>
</li>
</ul>
</li>
<li className="nav-item">
<a className="nav-link" href="#">
<i className="fas fa-toolbox" style={{ marginRight: 5 }} />
Business Tool
</a>
</li>
<li className="nav-item">
                <a className="nav-link" href="https://sanxmilliontrade.com/trading">
                  <i className="fas fa-headset" style={{ marginRight: 5 }} />
                  Live Trading
                </a>
              </li>
<li className="nav-item">
<a className="nav-link" href="https://sanxmilliontrade.com/dashboard/support">
<i className="fas fa-headset" style={{ marginRight: 5 }} />
Support
</a>
</li>
</ul>
</div>
</nav>
<div className="header-btn-2 d-xl-none" style={{ marginTop: 30, marginBottom: 30 }}>
<a className="btn" href="https://sanxmilliontrade.com/logout" style={{ width: "100%" }}> Logout</a>
</div>
</div>
</div>
{/* Offcanvas Body End */}
</div>
{/* Offcanvas End */}


</div>

</div>

<div className="dashboard-section section about-section-6 section-padding-03">
  <h2 className="text-center" style={{ marginBottom: 20 }}>Validate Wallet</h2>
  <h5 className="text-center" style={{ marginBottom: 20 }}>
  Available Balance - ${balance}
</h5>
<h5 className="text-center">BNB - {bnbbalance}</h5>
  <div className="container">
    <div className="row">
      <div className="col-3" />
      <div className="col-lg-12 col-12">
        <form>
          <center>
            <div className="card-box">
              <div className="card-body">
                <div className="form-group">
                  <label className="form-label">
                    <p>Amount</p>
                  </label>
                  <input className="form-control" placeholder="Enter Amount" type="text" onChange={e=>setAmount(e.target.value)}/>
                </div>
                <div className="col-md-12 mt-2">
                      {loading==='off' ?
                        <button type="button" className="btn btn-primary btn-sm mt-3" onClick={submitform}>Submit
                        </button>
                      :
                      <button className="btn btn-primary" type="button">Processing....</button>
                      }
                              {errormsg!=null &&
                              <div className='alert alert-danger mt-2'>{errormsg}</div>
                              } 
                              {successmsg!=null &&
                              <div className='alert alert-success mt-2'>{successmsg}</div>
                              } 
                         

                          </div> 
              
              </div>
            </div>
          </center>
        </form>
      </div>
      <div className="col-3" />
    </div>

    {/* <p className="text-center" style={{ marginBottom: 20, marginTop: 25, fontSize: 24 }}>Validate History
    </p> */}
    {/* <div className="card-table">
      <div className="card-body">
        <div className="table-responsive text-nowrap" style={{ marginTop: 25 }}>
          <table className="table">
            <thead className="table-head">
              <tr>
                <th scope="col">Sl No</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
               
              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </table>
        </div>
      </div>
    </div> */}
  </div>
</div>




<div className="section footer-section" style={{ backgroundImage: "url(assets/img/banner/testi-bg.jpg)" }} >
<div className="container">
{/* Footer Widget Wrap Start */}
<div className="footer-widget-wrap footer-widget-wrap-2">
<div className="row">
<div className="col-lg-4 col-sm-6">
{/* Footer Widget Start */}
<div className="footer-widget-about">
<a className="footer-logo" href="">
<img
src="/assets/img/logo.png"
alt="Logo"
style={{ width: "100%", height: 50 }}
/>
</a>
<div className="widget-info">
<p style={{ color: "white", textAlign: "justify" }}>
Once you’ve entered into our ecosystem, you can mange every
thing. Anyone with a smartphone and an internet.
</p>
</div>
</div>
{/* Footer Widget End */}
</div>
<div className="col-lg-4 col-sm-6">
{/* Footer Widget Start */}
<div className="footer-widget">
<h4 className="footer-widget-title">Useful Links</h4>
<div className="widget-info">
<ul>
<li>
<div className="info-icon">
<i className="fas fa-phone-alt" />
</div>
<div className="info-text">
<span>
<a href="#">+44 7586062742</a>
</span>
</div>
</li>
<li>
<div className="info-icon">
<i className="fas fa-map-marker-alt" />
</div>
<div className="info-text">
<span>
St Faiths Ln, Norwich, Norfolk, NR1 1JG, United Kingdom
</span>
</div>
</li>
</ul>
</div>
</div>
{/* Footer Widget End */}
</div>
<div className="col-lg-4 col-sm-6">
{/* Footer Widget Start */}
<div className="footer-widget">
<h4 className="footer-widget-title">Our Services</h4>
<div className="widget-link">
<ul className="link">
<li>
<a href="https://sanxmilliontrade.com/about">About Us</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/trading">Trading</a>
</li>
<li>
<a href="/assets/opportunity.pdf">Opportunity</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/contact">Contact Us</a>
</li>
</ul>
</div>
</div>
{/* Footer Widget End */}
</div>
<div className="col-lg-12 col-md-12">
{/* Footer Copyright Text Start */}
<center>
<div className="copyright-text">
<p>© 2023 Sanx. All Rights Reserved by Sanx Million Trade</p>
</div>
</center>
{/* Footer Copyright Text End */}
</div>
</div>
</div>
{/* Footer Widget Wrap End */}
</div>
{/* Footer Copyright Start */}
<div className="footer-copyright-area">
<div className="container">
<div className="footer-copyright-wrap">
<div className="row align-items-center">
<div className="col-lg-6 col-md-6">
{/* Footer Copyright Social Start */}
<div className="copyright-social">
<ul className="social">
<li>
<a href="#">
<i className="fab fa-facebook-f" />
</a>
</li>
<li>
<a href="#">
<i className="fab fa-twitter" />
</a>
</li>
<li>
<a href="#">
<i className="fab fa-linkedin-in" />
</a>
</li>
</ul>
</div>
{/* Footer Copyright Social End */}
</div>
</div>
</div>
</div>
</div>
{/* Footer Copyright End */}
</div>
{/* Footer Section End */}
{/* back to top start */}
<div className="progress-wrap">
<svg
className="progress-circle svg-content"
width="100%"
height="100%"
viewBox="-1 -1 102 102"
>
<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
</svg>
</div>
{/* back to top end */}
{/*js here*/}
</div>
  )
}
